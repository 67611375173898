.ColorPickerInput__wrapper{
  display: grid;
  grid-template-columns: repeat(10, 40px);
}

.ColorPickerInput__label{
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  transform: translate(0, 5px) scale(0.75);
  transform-origin: top left;
  display: block;
  margin-bottom: 1rem;
}

.ColorPickerInput__input {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 10px solid white;
}

.ColorPickerInput__input input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.ColorPickerInput__input input[type="radio"]:before {
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 4px;
  display: block;
}

.ColorPickerInput__input input[type="radio"]:hover:not(:checked) {
  outline: 2px solid #7460F6;
  transform: scale(1.4);
  border-radius: 4px;
}

.ColorPickerInput__input input[type="radio"]:checked::before {
  outline: 2px solid #7460F6;
  transform: scale(1.4);
  background-image: url("../assets/tick.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 45% 45%;
}
