body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* NOTE: used on close button on filters */
.filter-field {
  align-items: center !important;
  height: 64px;
  padding-top: 16px;
}

.MuiToolbar-gutters .MuiButton-label{
  white-space: nowrap !important;
}